<template>
  <v-card elevation="0">
    <v-card-title>
      Mapped Standards
      <v-btn icon @click="addDialog.showDialog = true">
        <v-icon>mdi-plus-circle</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-data-table
        dense
        show-select
        :items="appliedStandards"
        :headers="tableHeaders"
        v-model="selectedStandards"
        hide-default-footer>
      </v-data-table>
      <v-dialog width="80%" v-model="addDialog.showDialog" persistent>
        <v-card rounded shaped class="dialog-content">
          <v-card-title>Add Standard Mapping</v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-select
                    :items="standards"
                    v-model="addDialog.selectedStandard"
                    item-text="standardSetName"
                    clearable
                    return-object
                    placeholder="Standard Set">
                  </v-select>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="addDialog.searchString"
                    placeholder="Search String">
                    <template slot="append"><v-icon>mdi-magnify</v-icon></template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="scrolling-table">
                  <v-data-table
                    v-model="addDialog.selectedStandards"
                    :headers="addDialog.tableHeaders"
                    :items="standardDetails"
                    show-select
                    dense>

                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="red lighten-2" @click="cancelDialog">Cancel</v-btn>
            <v-btn text color="primary" @click="saveStandards">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
    <v-card-actions>
      <v-btn text color="red"
        :disabled="selectedStandards.length === 0"
        @click="deleteStandards">
        <v-icon>mdi-trash-can</v-icon>Delete
      </v-btn>
      <slot name="buttonRow">
      </slot>
    </v-card-actions>
  </v-card>
</template>

<script>
import Hierarchy from '../services/hierarchy.service';

export default{
  props: {
    selectedNode: {
      type: Object,
      default: null,
    },
    selectedTag: {
      type: Object,
      default: null,
    },
    appliedStandards: {
      type: Array,
      default: () => [],
    }
  },
  emits: [ 'save', 'delete' ],
  data() {
    return {
      tableHeaders: [
        { text: 'Standard Set', align: 'start', sortable: false, value: 'displayName' },
        { text: 'Standard Code', align: 'start', sortable: false, value: 'code' },
        { text: 'Description', align: 'start', sortable: false, value: 'name' },
      ],
      selectedStandards: [],
      addDialog: {
        showDialog: false,
        selectedStandard: '',
        searchString: '',
        tableHeaders: [
          { text: 'Standard Set', align: 'start', sortable: false, value: 'standardName' },
          { text: 'Standard Code', align: 'start', sortable: false, value: 'humanCodingScheme' },
          { text: 'Description', align: 'start', sortable: false, value: 'fullStatement' },
        ]
      },
    }
  },
  computed: {
    standards() {
      return this.$store.getters.CurriculumStandards;
    },
    standardDetails() {
      return this.$store.getters.StandardDetails
        .filter((sd) => !this.appliedStandards.map((s) => s.identifier).includes(sd.identifier))
        .filter((sd) => !this.addDialog.selectedStandard || sd.docIdentifier === this.addDialog.selectedStandard.docIdentifier)
        .filter((sd) => this.addDialog.searchString.length === 0 ||
          sd.humanCodingScheme?.toUpperCase().includes(this.addDialog.searchString.toUpperCase()) ||
          sd.fullStatement.toUpperCase().includes(this.addDialog.searchString.toUpperCase()));
    },
  },
  methods: {
    clearDialog() {
      this.addDialog.selectedStandard = null;
      this.addDialog.searchString = '';
      this.addDialog.showDialog = false;
    },
    cancelDialog() {
      this.clearDialog();
    },
    parseNodeType(nodeType) {
      switch(nodeType) {
        case 'QUESTION':
          return 'Question';
        case 'TEST':
          return 'Assessment';
        default:
          return 'UNKNOWN';
      }
    },
    saveStandards() {
      if (this.selectedNode) {
        this.$store.dispatch('toggleLoadingOverride');
        const savePromises = [];
        this.addDialog.selectedStandards.forEach((st) => {
          savePromises.push(Hierarchy.StandardSets.CreateCorrelation({
            reference: this.selectedNode.sysId,
            refHierarchyPointer: this.parseNodeType(this.selectedNode.type),
            correlationType: 'exact',
            caseIdentifier: st.identifier,
            caseDocIdentifier: st.docIdentifier,
            curriculumGuid: this.$store.getters.CurriculumGuid
          }, this.$store.getters.WebconUser));
        });

        Promise.all(savePromises)
          .then(() => {
            this.clearDialog();
            this.$store.dispatch('toggleLoadingOverride');
            this.$store.dispatch('getSelectedNodeStandards');
          });
      } else {
        this.$emit('save', {
          tag: this.$props.selectedTag,
          standards: this.addDialog.selectedStandards,
        });
        this.clearDialog();
      }
    },
    deleteStandards() {
      if (this.selectedNode) {
        this.$store.dispatch('toggleLoadingOverride');
        const deletePromises = [];
        this.selectedStandards.forEach((st) => {
          deletePromises.push(Hierarchy.StandardSets.DeleteCorrelation(st.id, this.$store.getters.WebconUser));
        });

        Promise.all(deletePromises)
          .then(() => {
            this.$store.dispatch('toggleLoadingOverride');
            this.$store.dispatch('getSelectedNodeStandards');
          });
      } else {
        this.$emit('delete', {
          tag: this.$props.selectedTag,
          standards: this.selectedStandards,
        });
        this.clearDialog();
      }
    }
  },
  watch: {
    appliedStandards() {
      let standard;
      this.appliedStandards.forEach((std) => {
        standard = this.standardDetails.filter((s) => s.caseIdentifier === std.caseIdentifier)[0];
        std.standardName = standard.standardName;
        std.humanCodingScheme = standard.humanCodingScheme;
        std.fullStatement = standard.fullStatement;
      })
    }
  },
}
</script>

<style scoped>
.v-card__title {
  font-weight: normal;
  font-size: 1em;
  padding-top: 0;
  padding-bottom: 0;
}
.dialog-content > .v-card__title {
  padding-bottom: 0;
}
.dialog-content >>> .col {
  padding-top: 0;
}
.scrolling-table {
  max-height: 330px;
  overflow-y: scroll;
}
</style>