import axios from 'axios';

const rootUrl = process.env.VUE_APP_HIERARCHY_URL;

const apiKey = process.env.VUE_APP_HIERARCHY_ID;
const apiSecret = process.env.VUE_APP_HIERARCHY_SECRET;

const tokenUrl = `${rootUrl}/connect/token`;

let token = '';
let tokenExpire = '';

async function doAuth() {
  if (token && tokenExpire > new Date()) {
    return token;
  }
  const url = tokenUrl;
  const response = await axios(
    {
      url: url,
      method: 'POST',
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
      data: `grant_type=client_credentials&scope=api&client_id=${apiKey}&client_secret=${apiSecret}`,
    }
  );
  token = response.data.access_token;
  const dt = new Date();
  tokenExpire = new Date(dt.setMinutes(dt.getMinutes() + (response.data.expires_in / 60) - 5));
}

export default {
  Curriculum: {
    async Get(curriculumGuid) {
      await doAuth();

      let url = `${rootUrl}/curriculum/${curriculumGuid}`;
      
      const response = await axios({
          url: url,
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          }
        });

      return response.data;
    },
  },
  StandardSets: {
    async GetAppliedToCurriculum(curriculumGuid) {
      await doAuth();

      const url = `${rootUrl}/correlation/curriculum/${curriculumGuid}/applied`;

      const response = await axios(
        {
          url: url,
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      return response.data;
    },
    async GetAppliedToQuestion(questionId) {
      await doAuth();

      const url = `${rootUrl}/correlation/question/${questionId}/applied`;

      const response = await axios(
        {
          url: url,
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      return response.data;
    },
    async GetAppliedToAssessment(assessmentId) {
      await doAuth();

      const url = `${rootUrl}/correlation/assessment/${assessmentId}/applied`;

      const response = await axios(
        {
          url: url,
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      return response.data;
    },
    async GetAvailable(curriculumGuid) {
      await doAuth();

      const url = `${rootUrl}/correlation/curriculum/${curriculumGuid}/available`;

      const response = await axios(
        {
          url: url,
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      return response.data;
    },
    async CreateCorrelation(payload, webconUser) {
      await doAuth();
  
      const url = `${rootUrl}/correlation`;
      const response = await axios(
        {
          url: url,
          method: 'PUT',
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
            'webcon-user': webconUser,
          },
          data: JSON.stringify(payload),
        }
      ).catch(err => {
        console.log('Error %o', err);
      });
  
      return response.data;
    },
    async DeleteCorrelation(correlationId, webconUser) {
      await doAuth();

      const url = `${rootUrl}/correlation/${correlationId}`;
      const response = await axios({
        url: url,
        method: 'DELETE',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
          'webcon-user': webconUser,
        }
      }).catch(err => {
        console.error('Error: %O', err);
      });

      return response.data;
    }
  },
  Standards: {
    async GetStandards() {
      await doAuth();
  
      const url = `${rootUrl}/correlation/standards`;
      const response = await axios(
        {
          url: url,
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      return response.data;
    },
    async GetStandardDetails() {
      await doAuth();
  
      const url = `${rootUrl}/correlation/standards/details`;
      const response = await axios(
        {
          url: url,
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      return response.data;
    },
    }
}