<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>

<style>
.form-row {
  border: 0px solid black;
  min-height: 400px;
  max-height: 400px;
}
.no-buttons {
  min-height: 450px;
  max-height: 450px;
}
.with-buttons {
  min-height: 355px;
  max-height: 355px;
}
.scroll-content {
  overflow-y: scroll;
}
.content-col {
  min-height: 550px;
  max-height: 550px;
}
</style>