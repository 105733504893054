<template>
  <div>
    <v-overlay :value="showOverlay">
    </v-overlay>
    <v-container fluid>
      <v-row class="pb-0 mb-0">
        <v-col class="pb-0 mb-0">
          <v-btn
            v-if="currentView !== 'tree'"
            color="primary" @click="backClick">&lt; Back</v-btn>
        </v-col>
        <v-col xl="3" lg="4" md="8" sm="8" align="right" class="pb-0 mb-0">
          <v-btn
            class="mr-2"
            :disabled="currentView === 'tags'"
            color="primary" @click="tagsClick">System Tags</v-btn>
          <v-btn
            class="mr-2"
            :disabled="currentView === 'publish'"
            color="primary" @click="publishClick">Publish</v-btn>
          <v-btn
            class="mr-2"
            :disabled="currentView === 'add'"
            color="primary" @click="uploadClick">Add Package</v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-0" no-gutters>
        <v-col>
          <qti-tree-view
            v-show="currentView === 'tree'"
            :curriculum-guid="curriculumGuid">
          </qti-tree-view>
          <qti-import-view
            v-show="currentView === 'add'"
            :auto-refresh-status="currentView === 'add'"
            :auto-refresh-interval="30"
            :curriculum-guid="curriculumGuid">
          </qti-import-view>
          <qti-publish-view
            v-show="currentView === 'publish'"
            :auto-refresh-status="currentView === 'publish'"
            :auto-refresh-interval="30"
            :curriculum-guid="curriculumGuid">
          </qti-publish-view>
          <qti-system-tags-view
            v-show="currentView === 'tags'">
          </qti-system-tags-view>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import QtiTreeView from '../forms/QtiTreeView.vue';
import QtiImportView from '../forms/QtiImportView.vue';
import QtiPublishView from '../forms/QtiPublishView.vue';
import QtiSystemTagsView from '../forms/QtiSystemTagsView.vue';

export default {
  components: {
    QtiTreeView,
    QtiImportView,
    QtiPublishView,
    QtiSystemTagsView,
  },
  data() {
    return {
      webConUser: '',
      currentView: 'tree'
    };
  },
  computed: {
    showOverlay() {
      return this.$store.getters.Loading;
    },
    curriculumGuid() {
      return this.$route.params.curriculumGuid
    }
  },
  methods: {
    receiveFromParent(event) {
      if (event.origin !== process.env.VUE_APP_PARENT_IFRAME) {
        //console.log('Unexpected Origin');
        return;
      }
      console.debug('Recieved Message from Parent - Event: %O', event);

      const { data } = event;
      const { configuration } = data;
      const { sdkConfiguration } = configuration;

      if (data.message !== 'SET_CONFIGURATION') {
        console.error(`Expected Message mismatch. Received ${event.message}`);
        return;
      }
      if (!sdkConfiguration) {
        console.error('Received invalid SdkCnfiguratin Object. Check Webcon configurations');
        return;
      }

      if (sdkConfiguration.webconUser) {
        this.$store.dispatch('setWebconUser', sdkConfiguration.webconUser);
        this.webConUser = sdkConfiguration.webconUser
      } else {
        console.error('Missing WebCon User form sdkConfiguration Object.  Chekc Webcon configurations');
      }
    },
    uploadClick() {
      this.currentView = 'add';
    },
    publishClick() {
      this.currentView = 'publish';
    },
    backClick() {
      this.currentView = 'tree';
    },
    tagsClick() {
      this.currentView = 'tags';
    }
  },
  mounted() {
    window.addEventListener('message', this.receiveFromParent, false);

    window.parent.postMessage({ message: 'IFRAME_LOADED' }, '*');

    if (process.env.VUE_APP_MODE === 'Development') {
      this.$store.dispatch('setWebconUser', 'rrastelli@gedu-pcg-demo.com');
    }
    
    // Dispatch events to Store
    this.$store.dispatch('getCurriculum', this.curriculumGuid);
    this.$store.dispatch('getQtiAssessments', this.curriculumGuid);
    this.$store.dispatch('getVendors');
    this.$store.dispatch('getTags');
    this.$store.dispatch('getUniqueTags');
    this.$store.dispatch('getStandards');
    this.$store.dispatch('getCurriculumStandards');
    // this.$store.dispatch('getStandardDetails');
  },
}
</script>