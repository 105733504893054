import Vue from 'vue'
import Vuex from 'vuex'

import Standards from './standards';

import Qti from '../services/qti.service';
import Hierarchy from '../services/hierarchy.service';

import Transform from '../services/transform.service';

Vue.use(Vuex)

function buildQtiTree(state) {
  const root = Transform.Curriculum.toTreeViewNode(state.curriculum);
  const assessments = [];
  const questions = [];

  state.qtiAssessments.forEach(asmt => {
    questions.length = 0;
    let tvnAssesment = Transform.Assessment.toTreeViewNode(asmt)

    state.qtiQuestions.forEach(qstn => {
      if (qstn.assessmentReference !== asmt.assessmentReference) {
        return;
      }
      tvnAssesment.children.push(Transform.Question.toTreeViewNode(qstn));
    });

    assessments.push(tvnAssesment);
  });

  root.children = assessments;

  return [root];
}

function timeDiffMinutes(dtStart, dtEnd) {
  const diffYears = dtEnd.getYear() - dtStart.getYear();
  const diffDays = dtEnd.getDay() - dtStart.getDay();
  const diffHours = dtEnd.getHours() - dtStart.getHours();
  const diffMinutes = dtEnd.getMinutes() - dtStart.getMinutes();

  return diffMinutes + (diffHours * 60) + (diffDays * 60 * 24) + (diffYears * 60 * 24 * 365);
}

function getQtiQuestion(state) {
  const qtiQuestIdx = state.qtiQuestions.map((quest) => quest.id).indexOf(state.selectedNode.id);

  return state.qtiQuestions[qtiQuestIdx];
}

export default new Vuex.Store({
  state: {
    qtiAssessments: [],
    qtiQuestions: [],
    vendors: [],
    packages: [],
    tags: [],
    uniqueTags: [],
    importStatuses: [],
    exportStatuses: [],
    selectedVendorId: 0,
    selectedNode: {},
    selectedNodeStandards: [],
    packageListLoadTime: '',
    curriculumGuid: '',
    curriculum: {},
    curriculumStandardSets: [],
    webconUser: '',
    loadingVendors: false,
    loadingItems: false,
    loadingCurriculum: false,
    loadingStandards: false,
    loadingPackages: false,
    loadingTags: false,
    loadingOverride: false,
  },
  getters: {
    QtiAssessments: (state) => state.qtiAssessments,
    QtiQuestions: (state) => state.qtiQuestions,
    QtiTags: (state) => state.tags,
    UniqueTags: (state) => state.uniqueTags,
    Vendors: (state) => state.vendors,
    Packages: (state) => state.packages,
    PackageLoadTime: (state) => state.packageListLoadTime,
    HangfireImportStatus: (state) => Transform.HangfireJobs.toUIObject(state.importStatuses),
    HangfireExportStatus: (state) => Transform.HangfireJobs.toUIObject(state.exportStatuses),
    Loading: (state) => state.loadingVendors || state.loadingItems || state.loadingCurriculum || state.loadingPackages || state.loadingTags || state.loadingStandards || state.loadingOverride,
    CurriculumGuid: (state) => state.curriculumGuid,
    Curriculum: (state) => state.curriculum,
    CurriculumStandards: (state) => state.curriculumStandardSets.items,
    QtiTree: (state) => buildQtiTree(state),
    WebconUser: (state) => state.webconUser,
    SelectedNode: (state) => state.selectedNode,
    SelectedNodeStandards: (state) => state.selectedNodeStandards.items,
    SelectedQuestion: (state) => getQtiQuestion(state),
  },
  mutations: {
    SET_LOADING_ITEMS(state, payload) {
      state.loadingItems = payload;
    },
    SET_LOADING_VENDORS(state, payload) {
      state.loadingVendors = payload;
    },
    SET_LOADING_CURRICULUM(state, payload) {
      state.loadingCurriculum = payload;
    },
    SET_LOADING_STANDARDS(state, payload) {
      state.loadingStandards = payload;
    },
    SET_LOADING_PACKAGES(state, payload) {
      state.loadingPackages = payload;
    },
    SET_LOADING_TAGS(state, payload) {
      state.loadingTags = payload;
    },
    SET_LOADING_OVERRIDE(state, payload) {
      state.loadingOverride = payload;
    },
    SET_QTI_ASSESSMENTS(state, payload) {
      state.qtiAssessments = payload;
    },
    SET_QTI_QUESTIONS(state, payload) {
      state.qtiQuestions = payload;
    },
    SET_UNIQUE_TAGS(state, payload) {
      state.uniqueTags = payload;
    },
    SET_VENDORS(state, payload) {
      state.vendors = payload;
    },
    SET_SELECTED_VENDOR_ID(state, payload) {
      state.selectedVendorId = payload;
    },
    SET_SELECTED_NODE(state, payload) {
      state.selectedNodeStandards = [];
      state.selectedNode = payload;
    },
    SET_SELECTED_NODE_STANDARD_SETS(state, payload) {
      state.selectedNodeStandards = payload;
    },
    SET_TAGS(state, payload) {
      state.tags = payload;
    },
    SET_PACKAGES(state, payload) {
      state.packages = payload;
    },
    SET_PACKAGE_LIST_LOAD_TIME(state, payload) {
      state.packageListLoadTime = payload;
    },
    SET_HANGFIRE_IMPORT_STATUSES(state, payload) {
      state.importStatuses = payload;
    },
    SET_HANGFIRE_EXPORT_STATUSES(state, payload) {
      state.exportStatuses = payload;
    },
    SET_CURRICULUM_GUID(state, payload) {
      state.curriculumGuid = payload;
    },
    SET_CURRICULUM(state, payload) {
      state.curriculum = payload;
    },
    SET_CURRICULUM_STANDARD_SETS(state, payload) {
      state.curriculumStandardSets = payload;
    },
    SET_WEBCONUSER(state, payload) {
      state.webconUser = payload;
    },
    UPDATE_ASSESSMENT_TITLE(state, payload) {
      state.qtiAssessments[payload.id].title = payload.name;
    },
    CLEAR_VENDORS(state) {
      state.vendors = [];
    },
    CLEAR_ASSESSMENTS(state) {
      state.qtiAssessments = [];
      state.qtiQuestions = [];
    },
    CLEAR_CURRICULUM(state) {
      state.curriculumGuid = '';
      state.curriculum = {};
    },
    CLEAR_PACKAGES(state) {
      state.selectedVendorId = 0;
      state.packages = [];
    },
    CLEAR_HANGFIRE_STATUS(state) {
      state.statuses = [];
    },
  },
  actions: {
    toggleLoadingOverride({ commit, state }) {
      commit('SET_LOADING_OVERRIDE', !state.loadingOverride);
    },
    setWebconUser({ commit }, webconUser) {
      commit('SET_WEBCONUSER', webconUser);
    },
    async getCurriculum({ commit, state }, curriculumGuid) {
      if (state.loadingCurriculum) {
        console.debug('STORE - Curriculum currently loading');
        return;
      }
      if(state.curriculumGuid === curriculumGuid) {
        console.debug('STORE - Curriculum already loaded');
        return;
      }
      commit('SET_LOADING_CURRICULUM', true);
      commit('CLEAR_CURRICULUM');
      commit('SET_CURRICULUM_GUID', curriculumGuid);
      commit('SET_CURRICULUM', await Hierarchy.Curriculum.Get(curriculumGuid));
      commit('SET_LOADING_CURRICULUM', false);
    },
    async clearAssessmentData({ commit }) {
      console.debug('STORE - Clearing Assessment data')
      commit('CLEAR_ASSESSMENTS');
    },
    async getQtiAssessments({ commit, state }, curriculumGuid) {
      if (state.loadingItems) {
        console.debug('STORE - Assessments currently loading');
        return;
      }
      commit('SET_LOADING_ITEMS', true);
      commit('CLEAR_ASSESSMENTS');
      // Replace with a call to service
      const promises = [Qti.Assessments.GetAll(curriculumGuid), Qti.Questions.GetAll(curriculumGuid)];

      Promise.all(promises)
        .then((values) => {
          commit('SET_QTI_ASSESSMENTS', values[0]);
          commit('SET_QTI_QUESTIONS', values[1]);
        });

      commit('SET_LOADING_ITEMS', false);
    },
    async getVendors({ commit, state }) {
      if (state.loadingVendors) {
        console.debug('STORE - Vendors currently loading');
        return;
      }
      commit('SET_LOADING_VENDORS', true);
      commit('CLEAR_VENDORS');

      commit('SET_VENDORS', await Qti.Vendors.GetAll());

      commit('SET_LOADING_VENDORS', false);
    },
    async getVendorPackages({ commit, state }, vendorId) {
      if (state.loadingPackages || (state.selectedVendorId === vendorId && timeDiffMinutes(state.packageListLoadTime, new Date()) < 5)) {
        console.log('Loading in progress OR request for same Vendor Id and less than 5 minutes since last request');
        return;
      }
      commit('SET_LOADING_PACKAGES', true);
      commit('CLEAR_PACKAGES');

      const packages = await Qti.Packages.GetAll(vendorId, state.webconUser);
      commit('SET_SELECTED_VENDOR_ID', vendorId);
      commit('SET_PACKAGES', Transform.Package.toUIObject(packages));

      commit('SET_PACKAGE_LIST_LOAD_TIME', new Date());
      commit('SET_LOADING_PACKAGES', false);
    },
    async getHangfireStatuses({ commit, state }) {
      if (!state.curriculumGuid || state.curriculumGuid.length === 0) {
        console.debug('STORE - Skipping Hangfire Refresh - CurriculumGuid is empty')
        return;
      }

      commit('CLEAR_HANGFIRE_STATUS');
      commit('SET_HANGFIRE_IMPORT_STATUSES', await Qti.HangfireJobs.GetImportJobs(state.curriculumGuid));
      commit('SET_HANGFIRE_EXPORT_STATUSES', await Qti.HangfireJobs.GetExportJobs(state.curriculumGuid));
    },
    async setSelectedNode({ commit }, node) {
      commit('SET_SELECTED_NODE', node);
      this.dispatch('getSelectedNodeStandards');
    },
    async clearSelectedNode({ commit }) {
      commit('SET_SELECTED_NODE', {});
    },
    async updateAssessment({ commit, state}, assessment) {
      const assessmentIdx = state.qtiAssessments.map((asmt) => asmt.id).indexOf(assessment.id);
      
      const targetAssessment = state.qtiAssessments[assessmentIdx];
      targetAssessment.title = assessment.name;

      // Update the Server
      await Qti.Assessments.Save(targetAssessment,
        state.webconUser);

      // Update the STore
      commit('UPDATE_ASSESSMENT_TITLE', {
        id: assessmentIdx,
        name: assessment.name,
      });
    },
    async getTags({ commit, state}, references) {
      if (state.loadingTags) {
        console.debug('STORE - Tags already loading');
        return;
      }

      commit('SET_LOADING_TAGS', true);
      commit('SET_TAGS', await Qti.Tags.GetAll(state.curriculumGuid, references?.assessmentReference, references?.questionReference));
      commit('SET_LOADING_TAGS', false);
    },
    async getCurriculumStandards({ commit, state}) {
      if (state.loadingStandards) {
        console.debug('STORE - Loading Standards');
      }

      commit('SET_LOADING_STANDARDS', true);
      commit('SET_CURRICULUM_STANDARD_SETS', await Hierarchy.StandardSets.GetAppliedToCurriculum(state.curriculumGuid));
      commit('SET_LOADING_STANDARDS', false);
    },
    async getSelectedNodeStandards({ commit, state }) {
      if (!state.selectedNode || !state.selectedNode.type) {
        console.debug('STORE - Selected Node not set');
        return;
      }

      const appliedSet = state.selectedNode.type === 'TEST' ? Hierarchy.StandardSets.GetAppliedToAssessment(state.selectedNode.id) : Hierarchy.StandardSets.GetAppliedToQuestion(state.selectedNode.id);

      const appliedStandards = await appliedSet;

      appliedStandards.items.forEach((i) => {
        i.displayName = i.standardSetName.match(/[A-Z:(0-9)]/g).join('');
      });

      commit('SET_LOADING_STANDARDS', true);
      commit('SET_SELECTED_NODE_STANDARD_SETS', appliedStandards);
      commit('SET_LOADING_STANDARDS', false);
    },
    async getUniqueTags({ commit, state }) {
      if (!state.curriculumGuid) {
        console.debug('STORE - Curriculum GUID not set');
        return;
      }

      commit('SET_LOADING_TAGS', true);
      commit('SET_UNIQUE_TAGS', await Qti.Tags.GetUnique(state.curriculumGuid));
      commit('SET_LOADING_TAGS', false);
    }
  },
  modules: {
    Standards,
  }
});
