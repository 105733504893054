import { render, staticRenderFns } from "./StandardsPanel.vue?vue&type=template&id=69a799be&scoped=true&"
import script from "./StandardsPanel.vue?vue&type=script&lang=js&"
export * from "./StandardsPanel.vue?vue&type=script&lang=js&"
import style0 from "./StandardsPanel.vue?vue&type=style&index=0&id=69a799be&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69a799be",
  null
  
)

export default component.exports