<template>
  <v-expansion-panels v-model="expanded">
    <v-expansion-panel>
      <v-expansion-panel-header :expand-icon="expandIcon">Question Details</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-container>
          <v-row>
            <v-col>
              <span class="property-label">Question Title:</span>
              <span>{{ question.name }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span class="property-label">Question Reference:</span>
              <span>{{ question.sysId }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span class="property-label">Question Type:</span>
              <span>{{ questionType }}</span>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col>
              <span class="property-label">Question Preview (Correct Answer Bolded)</span>
              <div v-html="questionXml"></div>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col>
              <span class="property-label">Question Preview:</span><br/>
              <!-- <iframe src="/qti/index.html?root=test1.xml" id="qtipreview" width="600px" height="350px"></iframe> -->
              <!-- <iframe id="qtipreview"
                v-resize="{ log: true }"
                width="100%"
                src="/qti/index.html?root=test1.xml"
                frameborder="0"
              ></iframe> -->
              <iframe id="qtipreview"
                v-resize.quiet="{ log: true }"
                width="100%"
                :src="iframeSource"
                frameborder="0"
              ></iframe>
              <input type="hidden" id="qtiXmlHidden" :value="qtiXml">
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span class="property-label">Correct Answer(s):</span><br/>
              <div v-html="correctAnswerXml"></div>
            </v-col>
          </v-row>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
function loadXmlDoc(filename) {
  let xhttp;
  if (window.ActiveXObject) {
    // eslint-disable-next-line no-undef
    xhttp = new ActiveXObject('Msxml2.XMLHTTP');
  } else {
    xhttp = new XMLHttpRequest();
  }

  xhttp.open('GET', filename, false);
  try {
    xhttp.responseType = 'msxml-document';
  } catch(err) { } // eslint-disable-line no-empty
  xhttp.send('');
  return xhttp.responseXML;
}

export default {
  data() {
    return {
      expanded: 0,
    };
  },
  computed: {
    question() {
      return this.$store.getters.SelectedNode;
    },
    qtiQuestion() {
      return this.$store.getters.SelectedQuestion;
    },
    iframeSource() {
      //added so that the iframe refreshes while switching between questions
      return "/qti/index.html?root=test1.xml&sysId=" + this.$store.getters.SelectedNode.sysId;
    },
    expandIcon() {
      if (this.expanded === 0) {
        return "mdi-minus";
      }
      return "mdi-plus";
    },
    questionType() {
      if  (!this.qtiQuestion) {
        return '';
      }
      switch(this.qtiQuestion.interactionType.toLowerCase() ) {
        case 'choiceinteraction':
          return 'Multiple Choice';
        case 'extendedtextinteraction':
          return 'Written Response';
        case 'textentryinteraction':
          return 'Short Answer';
        case 'hottextinteraction':
          return 'Choose the right text';
        case 'inlinechoiceinteraction':
          return 'Choose the right word';
        case 'matchinteraction':
          return 'Matching';
        case 'gapmatchinteraction':
          return 'Gap Match Interaction';
        case 'orderinteraction':
          return 'Order Interaction';
        case 'selectpointinteraction':
          return 'Select Point Interaction';
        case 'positionobjectinteraction':
          return 'Point Object Interaction';
        default:
          return this.qtiQuestion.interactionType;
      }
    },
    qtiXml() {
      const qtiQuestion = this.$store.getters.SelectedQuestion;
      if (!qtiQuestion && !qtiQuestion?.qtiXml) {
        // Current question is blank
        return '';
      }
      return qtiQuestion?.qtiXml;
    },
    correctAnswerXml() {
      const qtiQuestion = this.$store.getters.SelectedQuestion;
      if (!qtiQuestion && !qtiQuestion?.qtiXml) {
        // Current question is blank
        return '';
      }
      const qtiXmlString = qtiQuestion.qtiXml
        .replaceAll('&amp;lt;', '<')
        .replaceAll('&amp;gt;', '>')
        .replaceAll('&amp;nbsp;', ' ')
        .replaceAll('&amp;', '&')
        .replaceAll('<em>', '')
        .replaceAll('</em>', '')
        .replaceAll('<textentryinteraction responseidentifier="te_response_0"></textentryinteraction>', '_________');

      const parser = new DOMParser();
      let xml = parser.parseFromString(qtiXmlString, 'text/xml');
      let xsl = loadXmlDoc(`correctAnswer.xslt`);
      let ex;

      if (!xml || !xsl) {
        console.error('Failed to load XML and/or XSLT file.');
        return '<span>Error on Render</span>'
      }

      if (window.ActiveXObject) {
        ex = xml.transformNode(xsl);
      } else if (document.implementation && document.implementation.createDocument) {
        let xsltProcessor = new XSLTProcessor();
        xsltProcessor.importStylesheet(xsl);
        ex = xsltProcessor.transformToFragment(xml, document);
      }

      const serializer = new XMLSerializer();
      const result = serializer.serializeToString(ex)
        .replaceAll('&amp;lt;', '<')
        .replaceAll('&amp;gt;', '>')
        .replaceAll('&amp;nbsp;', ' ')
        .replaceAll('&amp;', ' and ')
        .replaceAll('<em>', '')
        .replaceAll('</em>', '')
        .replaceAll('xmlns:m', 'xmlns')
        .replaceAll('<m:', '<')
        .replaceAll('</m:', '</')
        .replaceAll('<textentryinteraction responseidentifier="te_response_0"></textentryinteraction>', '_________');

      return result;
    },
    questionXml() {
      const qtiQuestion = this.$store.getters.SelectedQuestion;
      if (!qtiQuestion && !qtiQuestion?.qtiXml) {
        // Current question is blank
        return '';
      }
      const parser = new DOMParser();
      let xml = parser.parseFromString(qtiQuestion.qtiXml, 'text/xml');
      let xsl = loadXmlDoc(`${qtiQuestion.interactionType}.xslt`);
      let ex;

      if (!xml || !xsl) {
        console.error('Failed to load XML and/or XSLT file.');
        return '<span>Error on Render</span>'
      }

      if (window.ActiveXObject) {
        ex = xml.transformNode(xsl);
      } else if (document.implementation && document.implementation.createDocument) {
        let xsltProcessor = new XSLTProcessor();
        xsltProcessor.importStylesheet(xsl);
        ex = xsltProcessor.transformToFragment(xml, document);
      }

      const serializer = new XMLSerializer();
      return serializer.serializeToString(ex)
        .replaceAll('&amp;lt;', '<')
        .replaceAll('&amp;gt;', '>')
        .replaceAll('&amp;', '&')
        .replaceAll('<em>', '')
        .replaceAll('</em>', '')
        .replaceAll('<textentryinteraction responseidentifier="te_response_0"></textentryinteraction>', '_________');
    }
  },
  methods: {
  },
  mounted() {
    console.log('in mounted');
    var data = { foo: 'bar' }
    var event = new CustomEvent('myCustomEvent', { detail: data });
    document.querySelector('#qtipreview').contentDocument.dispatchEvent(event);
    console.log('message sent');
  },
}
</script>

<style scoped>
.v-expansion-panel-header {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  word-break: break-all;
}
.property-label {
  margin: 0 4px 0 0;
  font-weight: bolder;
}
</style>

<style>
.question-text {
  margin: 0 5px;
}
  iframe {
    min-height: 300px;
    height:100%;
    min-width: 100%;
    border:  1px solid #e8e8e8;
  }
</style>
